$headerColor:#000;
$headerFontColor:#fff;
$bodycolor:#f5f5f5;
$greenmarkar:rgb(11, 190, 35);
$redmarkar:rgb(219, 6, 6);




$screen-sm-min: 768px;


@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}