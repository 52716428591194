@import "mapbox-gl/dist/mapbox-gl.css";
@font-face {
  font-family: gothomRound;
  src: url(assets/wiyankFont/tis_medium.ttf) format("truetype");
}
@font-face {
  font-family: gothomRoundBold;
  src: url(assets/wiyankFont/tistext_bold.ttf) format("truetype");
}
@font-face {
  font-family: gothomRoundMedium;
  src: url(assets/wiyankFont/tistext_medium.ttf) format("truetype");
}
@font-face {
  font-family: gothomRoundRegular;
  src: url(assets/wiyankFont/tistext_regular.ttf) format("truetype");
}
body {
  margin: 0px;
  padding: 0px;
  height: 100dvh;
  width: 100%;
  background-image: linear-gradient(174.2deg, rgb(255, 244, 228) 7.1%, rgb(240, 246, 238) 67.4%);
  font-family: "gothomRound", "gothomRoundBold", "gothomRoundMedium", "gothomRoundRegular";
}
body .bg-primary {
  background-color: #f5f5f5 !important;
}
body .collapse .navbar-nav .nav-item .nav-link {
  color: #000;
}
body .collapse .navbar-nav-right {
  color: #000;
}
body .collapse .jumbotron {
  padding: 2rem 2rem !important;
}
body .card {
  box-shadow: 0 0 10px 0 rgba(41, 41, 41, 0.4) !important;
}
body .col-md-12 .contant {
  background: #fff;
  padding: 2%;
}
body .col-md-12 .contant .contant_wrap_text {
  opacity: 0.7;
  font-size: 15px;
  font-weight: 200;
}
body .markar_wrap_icon_green {
  background-color: rgb(11, 190, 35);
  padding: 6px;
  height: 15px;
  width: 15px;
  border-radius: 60px;
}
body .markar_wrap_icon_red {
  background-color: rgb(219, 6, 6);
  padding: 6px;
  height: 15px;
  width: 15px;
  border-radius: 60px;
}
body .contant_wrap_pickup {
  font-size: 15px;
  opacity: 0.8;
  font-weight: 300;
}
body .contant_wrap_agm_maps {
  height: 100% !important;
}
@media (max-width: 768px) {
  body .contant_wrap_agm_maps {
    height: 71% !important;
  }
}
body .jumbotron1 {
  border-radius: 0px !important;
  background-color: #f5f5f5 !important;
}
body .rounded {
  border-radius: 500px !important;
  height: 70px;
  width: 70px;
}
body .fix_content_wrap {
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 9999;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.9);
}
body .fix_content_wrap .fixed_content {
  left: 32%;
  top: 32%;
  width: 35%;
  position: relative;
  height: 35%;
  color: white !important;
}
@media (max-width: 768px) {
  body .fix_content_wrap .fixed_content {
    width: 80%;
    left: 10%;
    overflow: hidden !important;
  }
}
body .img_wrap_fluid {
  width: 150px;
  height: 70px auto;
}

#map,
#restMap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
}

.marker {
  border: 0.3rem solid #000;
  background: #fff;
  padding: 0.3rem;
}

.roundMarker {
  background: #fff;
  border: 0.4rem solid #000;
  border-radius: 50%;
  padding: 0.3rem;
}

/* Media query for tablets */
@media only screen and (max-width: 768px) {
  /* Styles for tablets (e.g., iPad) */
  /* Add your tablet-specific styles here */
  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 70dvh;
  }
  #restMap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 70dvh;
  }
}
/* Media query for mobile devices */
@media only screen and (max-width: 480px) {
  /* Styles for mobile devices */
  /* Add your mobile-specific styles here */
  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 66dvh;
  }
  #restMap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 66lvh;
  }
}
.blackTextColor {
  color: #303136;
}

.info-window {
  background-color: #fff;
  border-radius: 62px;
  padding: 12px;
  height: 60px;
  box-shadow: 0 2px 4px rgba(23, 23, 23, 0.62);
  width: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-top {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  /* Change color as needed */
}

.pin-bottom {
  width: 20px;
  height: 20px;
  background-color: #fff;
  /* Change color as needed */
  border: 2px solid #333;
  /* Change color as needed */
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.mapboxgl-popup-content {
  background: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  pointer-events: auto;
  position: relative;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: #939393;
  box-shadow: 0px 0px 0px rgba(23, 23, 23, 0.62);
}